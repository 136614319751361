import React from 'react';

const Contact = () => {
    const validateForm = (event) => {
        // Implement your form validation logic here
        // Example: check if name and email are not empty
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        if (!name || !email) {
            alert('Please fill out both name and email fields.');
            event.preventDefault();
        }
    };

    return (
        <div>
            <div id="contact">
                <h3>Get in Touch</h3>
                <div className="about-head">📫 Have a question? Don't hesitate to contact me! 🙌</div>
                <div className="form-container">
                    <form
                        action="https://formsubmit.co/kadamdigvijay36@gmail.com"
                        method="POST"
                        onSubmit={validateForm}
                    >
                        <div className="input-box">
                            <div className="name">
                                <label>Name</label>
                                <input type="text" id="name" name="Name" />
                            </div>
                            <div className="email">
                                <label>Email</label>
                                <input type="email" id="email" name="Email" />
                            </div>
                        </div>
                        <div className="message-box">
                            <label>Message</label>
                            <textarea name="Message" cols="30" rows="10"></textarea>
                        </div>
                        <div>
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_next" value="https://digvijaykadam.netlify.app/Thankyou.html" />
                        </div>
                       
                        <button className="submitbtn" type="submit">Shoot 🚀</button>
                    </form>
                </div>
                <div className="social-icons">
                    <a className="animate__animated animate__slideInUp" href="https://www.linkedin.com/in/digvijay-web/" target="_blank"  rel="noreferrer">
                        <i className="bi bi-linkedin"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://github.com/Dcode36" target="_blank"  rel="noreferrer">
                        <i className="bi bi-github"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://instagram.com/dcode_06?igshid=OGQ5ZDc2ODk2ZA==" target="_blank"  rel="noreferrer">
                        <i className="bi bi-instagram"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://www.youtube.com/@digvi2550/playlists" target="_blank"  rel="noreferrer">
                        <i className="bi bi-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
