import React, { useState } from 'react';
import Menu from './Menu';
import img from './digvi.png'
const Nav = () => {
    const [open, setOpen] = useState(false); // Initial state set to false
    const handleOpenMenu = () => {
        setOpen(!open); // Toggle the open state
    };

    return (
        <>
            <div className="nav-main-container">
                <div className="navbar-container">
                    <div className="logo" data-aos="fade-down">
                        <img src={img} alt=""  height="80px"/>
                    </div>
                    <div data-aos="fade-down" className="menu-icon" onClick={handleOpenMenu}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <Menu open={open} setOpen={setOpen} /> {/* Pass the open state as a prop */}

            </div >
        </>
    );
};

export default Nav;
