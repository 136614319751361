// HomePage.js
import React from 'react';

import TypedText from './parts/Typed';
import Nav from './parts/Nav';

const HomePage = () => {
    return (
        <div className="hero-section" id="home">
            <Nav />
            <main>
                <p className="heading animate__animated animate__slideInUp">Hi. I'm Digvijay<br /></p>
                <p className="heading animate__animated animate__slideInUp" >
                    <TypedText />
                </p>
                <p className="para animate__animated animate__slideInUp">A passionate problem-solver, using design and code to simplify complexities.</p>
                <div className="social-icons">
                    <a className="animate__animated animate__slideInUp" href="https://www.linkedin.com/in/kadamdigvijay/" target="_blank" rel="noreferrer">
                        <i className="bi bi-linkedin"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://github.com/Dcode36" target="_blank" rel="noreferrer">
                        <i className="bi bi-github"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://instagram.com/dcode_06?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noreferrer">
                        <i className="bi bi-instagram"></i>
                    </a>
                    <a className="animate__animated animate__slideInUp" href="https://www.youtube.com/@digvi2550/playlists" target="_blank" rel="noreferrer">
                        <i className="bi bi-youtube"></i>
                    </a>
                </div>
            </main>
            <img className="mobile-visiblity" src="https://cdn.dribbble.com/users/1162077/screenshots/5403918/media/a85c0dcdcc774c6f340b07518363d6fb.gif" alt="" />
        </div>
    );
};

export default HomePage;
