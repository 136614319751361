// TypedText.js
import React from 'react';
import Typed from 'typed.js';

const TypedText = () => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Developer.', 'Designer.', 'Freelancer.'],
      typeSpeed: 90,
      backSpeed: 95,
      loop: true
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return <span ref={el} />;
};

export default TypedText;
