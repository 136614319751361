import React, { useState, useEffect } from 'react';
import About from './components/pages/About';
import HomePage from './components/pages/HomePage';
import Projects from './components/pages/Projects';
import Contact from './components/pages/Contact';
import Experience from './components/pages/Experience';
import Home from './components/pages/Home';
import AOS from 'aos';
import './index.css'
import 'aos/dist/aos.css';
import Loader from './components/pages/parts/Loader';
// import AboutPage from './components/pages/AboutPage';
// import Work from './components/pages/Work';

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    AOS.init();
    const timer = setTimeout(() => {
      setFadeOut(true); // Start transition to hide the loader
      setTimeout(() => setLoading(false), 1000); // Wait for transition to complete before removing loader
    }, 2000); // Show loader for 2 seconds

    // Cleanup timeout if component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className={`loader-container ${fadeOut ? 'fade-out' : ''}`}>
        <Loader />
      </div>
      {!loading && (
        <>
          {/* <Home />
          <AboutPage />
          <Work /> */}
          <HomePage />
          <About />
          <Experience />
          <Projects />
          <Contact />
        </>
      )}
    </div>
  );
}

export default App;
