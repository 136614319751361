import React from 'react';

const Menu = ({ open, setOpen }) => { // Receive the open and setOpen props
    const handleCloseMenu = () => {
        setOpen(false); // Set the open state to false
    };

    return (
        <div className={`menu-container ${open ? 'active' : ''}`}> {/* Conditionally add the active class */}
            <div className="menu-icon close" style={{ padding: "20px" }} onClick={handleCloseMenu}>
                <i className="bi bi-x-lg"></i>
            </div>
            <div className="link-container">
                <ul>
                    <li ><a href="#home" onClick={() => { setOpen(!open) }}>home</a></li>
                    <li ><a href="#about" onClick={() => { setOpen(!open) }}>about</a></li>
                    <li ><a href="#experience" onClick={() => { setOpen(!open) }}>work</a></li>
                    <li ><a href="#projects" onClick={() => { setOpen(!open) }}>projects</a></li>
                    <li ><a href="#contact" onClick={() => { setOpen(!open) }}>contact</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Menu;
