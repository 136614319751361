import React from 'react';

const About = () => {
    return (
        <div id="about">
            <h3>About Me</h3>
            <div className="about-head">
                Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology. 📚💻🌐
            </div>

            <div className="about-container">
                <div className="about-left">
                    <div className="left-content">
                        <h4>My Journey through lines of code!</h4>
                        <p>
                            Hello! I’m Digvijay Kadam, a Full-Stack Developer 🧑‍💻 dedicated to turning ideas into practical digital solutions. I’m in my final year of a BTech in Computer Science 🎓, with a solid grounding in both front-end and back-end development.
                            <br /><br />
                            I enjoy creating responsive, user-friendly interfaces 💻 and building reliable server-side applications ⚙️. I’m always open to challenges that help me improve my skills and broaden my knowledge. With each project, I focus on usability and quality, aiming to make a positive impact through my work 🌟.
                            <br /><br />
                            I’m committed to continuous learning 📚 and problem-solving. Let’s work together to bring your ideas to life! 🚀
                        </p>
                    </div>
                </div>

                <div className="about-right">
                    <div className="right-content">
                        <h4>My Skills</h4>
                        <div>
                            <span>HTML</span>
                            <span>CSS</span>
                            <span>JavaScript</span>
                            <span>React</span>
                            <span>Node.js</span>
                            <span>ExpressJS</span>
                            <span>MongoDB</span>
                            <span>Responsive Design</span>
                            <span>GIT</span>
                            <span>Github</span>
                            <span>Docker</span>
                            <span>Bootstrap</span>
                            <span>Figma</span>
                            <span>Canva</span>
                            <span>Java</span>
                            <span>Python(Basic)</span>
                            <span>DSA</span>
                            <span>Design</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
