import React from 'react';
import projectsData from '../data/projects.json';

const Projects = () => {
    return (
        <div id="projects">
            <h3>Projects</h3>
            <div className="about-head">A Showcase of Innovation and Creativity :)</div>
            {projectsData.map((category, index) => (
                <div key={index} className="container">
                    <h4>{category.title}</h4>
                    <br />
                    <div className="card-container">
                        {category.projects.map((project, projIndex) => (
                            <div key={projIndex} className="card">
                                <img className="image" src={project.image} alt={project.title} />
                                <div className="overlay">
                                    <div className="text">
                                        <p className='fs-3'>{project.title}</p>
                                        <p className='fs-6'>{project.description}</p>
                                        <span>
                                            {project.demoLink && (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={project.demoLink}
                                                    className="demo-btn"
                                                >
                                                    <i className="bi bi-link-45deg"></i> Demo
                                                </a>
                                            )}
                                            {project.codeLink && (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={project.codeLink}
                                                    className="demo-btn"
                                                >
                                                    <i className="bi bi-github"></i> Code
                                                </a>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <br /><br />
                </div>
            ))}
        </div>
    );
}

export default Projects;
