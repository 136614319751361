import React from 'react';
import '../pages/css/Work.css';

const Experience = () => {
    const experiences = [
        {
            title: "GDG Lead",
            company: "GDG SGU",
            type: "Full-time",
            duration: "Sep 2024 - Present",
            location: "Kolhapur, Maharashtra, India",
            description: "On-site",
            skills: ["Team Management", "Event Management", "Community Building", "Public Speaking"]
        },
        {
            title: "Full Stack Developer",
            company: "SGU's Consultancy Services",
            type: "Internship",
            duration: "Mar 2024 - Aug 2024",
            location: "Kolhar, Karnataka, India",
            description: "Worked on project management, software projects, and React.js development.",
            skills: ["Project Management", "Software Projects", "React.js"]
        },
        {
            title: "Software Developer Intern",
            company: "Bluecrest Software",
            type: "Internship",
            duration: "Nov 2023 - Mar 2024",
            location: "Remote",
            description: "Built UI components, integrated REST APIs, and created chatbot interfaces with a focus on smooth user interactions.",
            skills: ["React.js", "Material-UI", "Bootstrap", "GitHub", "Soft UI", "REST APIs"]
        },
        {
            title: "Professional Freelancer",
            company: "Freelance",
            type: "Freelance",
            duration: "Mar 2023 - Feb 2024",
            location: "Remote",
            description: "Collaborated with international clients to build web applications. Also served as an API Developer Instructor at SGU and AmtechNow.",
            skills: ["Project Management", "Software Development", "Consulting", "API Instructor"]
        },
        {
            title: "Web Development Intern",
            company: "Pusht Health Service",
            type: "Internship",
            duration: "Jul 2023 - Aug 2023",
            location: "Remote",
            description: "Designed high-converting landing pages, boosting LinkedIn engagement by 40% and improving lead conversions.",
            skills: ["Web Design"]
        },
        {
            title: "Full Stack Developer Intern",
            company: "Edureka",
            type: "Internship",
            duration: "Mar 2022 - Nov 2022",
            location: "Remote",
            description: "Gained hands-on experience with full-stack web development through real-world projects. Mentored and guided in various technologies.",
            skills: ["HTML", "Node.js", "JavaScript", "Git", "React.js", "CSS", "Bootstrap", "REST APIs", "MongoDB"]
        },
    ];

    return (
        <div id="experience" className="experience-section py-5">
            <h3 className="text-center mb-4">Work Experience</h3>
            <div className="container">
                <div className="row">
                    {experiences.map((exp, index) => (
                        <div className="col-md-6 mb-4" key={index}>
                            <div className="experience-card p-4 shadow-sm rounded">
                                <h5 className="mb-2">{exp.title} <span className="text-muted">@ {exp.company}</span></h5>
                                <p className="text-primary mb-1">{exp.duration}</p>
                                <p className="mb-1"><strong></strong> {exp.type}</p>
                                <p className="mb-1"><strong><i class="bi bi-geo-alt-fill"></i> </strong> {exp.location}</p>
                                <p className="mb-2"><strong>Skills:</strong> {exp.skills.join(", ")}</p>
                                <p className="text-muted">- {exp.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Experience;
